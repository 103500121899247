.prodCard {
    background-color: grey;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    max-width: 250px;
    height: 330px;
    margin: 20px;
    border-radius: 0.5em;
}

.prodImg {
    background-color: rgb(90, 88, 88);
    margin: 15px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5em 0.5em 0.5em 0.5em;
}

.prodCard img {
    width: 100%;
    max-width: 70px;
    max-height: 70px;
    margin: 20px;
}

.prodDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: left;
    text-align: left;
    padding: 0px 10px;
}

.prodDetails p {
    margin: 5px;
    overflow-y: auto;
    max-height: 60px;
}

.edit-button {
    width: 100%;
    display: flex;
    align-items: bottom;
    justify-content: flex-end;
    margin: 2px;
    /* justify-content: right; */
}

.edit-delete-button {
    width: 60px;
    margin: 10px;
    border-radius: 0.2em;
    border-color: transparent;
}

.edit-delete-button1 {
    width: 60px;
    margin: 10px;
    background-color: red;
    color: white;
    border-color: transparent;
    border-radius: 0.2em;
}

@media screen and (max-width:1300px) {
    .prodCard {
        width: 210px;
    }
}

@media screen and (max-width:560px) {
    .prodCard {
        width: 40%;
    }
}

@media screen and (max-width:540px) {
    .prodCard {
        width: 100%;
    }
}