.sign-img {
    width: 450px;
    height: 450px;
    margin: 30px 50px;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 20px;
}

.Sign {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0px 0px;
}

.sign-box {
    width: 100%;
    max-width: 600px;
    height: 100%;
    justify-content: left;
    align-items: last;
}

.Sign-input {
    box-sizing: border-box;
    border-radius: 4px;
    height: 35px;
    margin: 4px;
    width: 87.5%;
    padding-right: 40px;
    border-color: black;
    color: black;
}

select {
    border-radius: 4px;
    height: 35px;
    margin: 5px;
    /* padding-right: 40px; */
    width: 87.5%;
    background-color: #FFFFFF;
}


.sign-button {
    width: 80px;
    height: 25px;
    margin: 20px;
}

.city-state {
    width: 100%;
    display: flex;
}

.pass {
    position: relative;
    width: 100%;
}

.eye-icon {
    position: absolute;
    top: 50%;
    transform: translate(-40px, -45%);
    color: grey;
    cursor: pointer;
}

.eye-icon.active {
    color: #666;
    /* Adjust the color as per your preference */
}

@media screen and (max-width:1100px) {
    .wrapper {
        flex-direction: column;
    }

    .sign-img {
        width: 250px;
        height: 250px;
        margin: 30px;
    }
}