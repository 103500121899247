.bell-icon {
    color: rgb(75, 72, 72);
    transition: ease-in-out 0.4s;
}

.notification-dot {
    position: relative;
    top: -6px;
    right: 13px;
    width: 18px;
    height: 18px;
    color: black;
    font-size: 15px;
    text-align: center;
    background-color: rgb(33, 216, 33);
    border-radius: 50%;
}

#logo {
    width: 30px;
    float: right;
    margin: 15px;
    border-radius: 50%;

}

.hawker-menu ul {
    display: block !important;
}

.menu {
    display: flex;
    height: calc(300px/4);
    width: 100%;
    /* height: 100%; */
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: ease-in-out 0.3s;
}

.menu:hover {
    cursor: pointer;
    background-color: black;
}

.Profile {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-wrapper {
    width: 200px;
    height: 100%;
    margin: 0px 0px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.menu-link {
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    transition: ease-in-out 0.4s;
}

.menu-link:hover {
    background-color: black;
    cursor: pointer;

    .bell-icon {
        color: white;
    }
}

.menu-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hawker-menu .hidden {
    position: fixed;
    top: 5.5em;
    right: -250px;
    width: 180px;
    height: 300px;
    border-radius: 0.5em;
    margin: 0px;
    background: #2e3233;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: white;
    transition: right 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
}

.hawker-menu .visible {
    position: fixed;
    top: 5.5em;
    right: 10px;
    width: 180px;
    height: 300px;
    background: #2e3233;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 0.5em;
    margin: 0px;
    transition: right 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width:1100px) {
    .hawker-menu .hidden {
        position: fixed;
        top: 5.5em;
        right: -250px;
        width: 180px;
        height: 300px;
        margin: 0px;
        transition: right 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
    }

    .hawker-menu .visible {
        position: fixed;
        top: 5.5em;
        right: 10px;
        width: 180px;
        height: 300px;
        margin: 0px;
        transition: right 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
    }
}


/* @media screen and (max-width:500px) {

    .notification-dot {
        top: 25px;
        right: 16px;
        font-size: 15px;
    }
}

@media screen and (max-width:350px) {

    .notification-dot {
        top: 25px;
        right: 12px;
        font-size: 15px;
    }
} */