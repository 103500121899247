.UserProfile {
    background-color: white;
    width: 90%;
    margin: 10%;
    margin-top: 80px;
    height: 80%;
    color: black;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.UserProfile button {
    background-color: white;
    width: 80px;
    margin: 10px;
    padding: 2px;
    transition: ease-in-out 0.3s;
    border-color: transparent;
    border-radius: 0.3em;
}

.UserProfile button:hover {
    background-color: grey;
    color: white;
}

.editInput {
    box-sizing: border-box;
    border-radius: 0.3em;
    height: 35px;
    margin: 4px;
    width: 85%;
    padding: 3px;
    padding-right: 40px;
    border: 0.00001px solid black;
    color: black;
}

.card3 {
    background-color: rgb(0, 0, 0);
    width: 90%;
    max-width: 1200px;
    height: 100%;
    margin: 0px 5% 40px 5%;
    display: flex;
    justify-content: center;
    border-radius: 0em 0em 1em 1em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.edit {
    margin: 40px;
}

input:disabled {
    background-color: grey !important;
    color: white;
    border: 0.2px solid black;
}

input:disabled::placeholder {
    color: white;
}

select:disabled {
    background-color: grey;
    color: white;
    border: 0.3px solid black;
    /* border-color: transparent; */
}

