.App {
  
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

input:focus {
  outline: none;
}

select:focus {
  outline: none;
}

#switch-sign {
  color: white;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #999;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.Verified {
  font-size: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verify-wrapper {
  width: 80%;
}

img:hover {
  cursor: pointer;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width:700px) {
  .Verified {
    font-size: 15px;
    width: 100%;
    margin: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}