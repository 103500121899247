.card4 {
    background-color: grey;
    width: 90%;
    max-width: 1200px;
    height: 500px;
    margin: 40px 5% 0px 5%;
    border-radius: 1em 1em 0em 0em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card5 {
    background-color: rgb(0, 0, 0);
    width: 90%;
    max-width: 1200px;
    min-height: 500px;
    margin: 0px 5% 40px 5%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0em 0em 1em 1em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#fileInput {
    background-color: grey;
    width: 86px;
    color: white;
    height: 50px;
}

input::-webkit-file-upload-button:hover {
    background-color: black;
    border-color: transparent;
    color: white;
    cursor: pointer;
    text-align: center;
}

.chooseFileLabel {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    margin: 5px;
    color: #333;
    width: 220px;
}

input::-webkit-file-upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: grey;
    border-color: transparent;
    color: white;
    text-align: center;
    transition: ease-in-out 0.3s;
}

.card4 button {
    width: 50px;
    height: 25px;
    border-radius: 0.2em;
    background-color: transparent;
    border-color: transparent;
    color: white;
    transition: ease-in-out 0.3s;
}

.card4 button:hover {
    cursor: pointer;
    background-color: black;
    color: white;
}

.prodBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    width: 100%;
    height: 650px;
}

@media screen and (max-width:840px) {
    .prodBox {
        justify-content: space-around;
    }
}